@font-face {
  font-family: 'compassmedium';
  src: url('./static/fonts/compass.woff2') format('woff2'),
    url('./static/fonts/compass.woff') format('woff'),
    url('./static/fonts/compass.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'compassbold';
  src: url('./static/fonts/compassbold.woff2') format('woff2'),
    url('./static/fonts/compassbold.woff') format('woff'),
    url('./static/fonts/compassbold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'compass_ltregular';
  src: url('./static/fonts/compasslight.woff2') format('woff2'),
    url('./static/fonts/compasslight.woff') format('woff'),
    url('./static/fonts/compasslight.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

html {
  min-height: 100%;
  /* overflow: hidden; */
}
.ovHidden {
  overflow: hidden !important;
}

#root {
  min-height: 100vh;
}

body {
  margin: 0;
  font-family: 'compassmedium', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* General */
.textCenter {
  text-align: center;
}
.textRight {
  text-align: right;
}
.fullWidth {
  width: 100%;
}
.marginBottomSmall {
  margin-bottom: 1rem !important;
}
.marginBottomSmMd {
  margin-bottom: 1.4rem !important;
}
.marginBottomMedium {
  margin-bottom: 3rem !important;
}
.marginBottomLarge {
  margin-bottom: 6rem !important;
}
.paddingLeftSmall {
  padding-left: 1rem !important;
}
.paddingLeftMedium {
  padding-left: 3rem !important;
}
.paddingLeftLarge {
  padding-left: 6rem !important;
}
.paddingRightSmall {
  padding-right: 1rem !important;
}
.paddingRightMedium {
  padding-right: 3rem !important;
}
.paddingRightLarge {
  padding-right: 6rem !important;
}
.paddingTopXSmall {
  padding-top: 0.5rem !important;
}
.paddingTopSmall {
  padding-top: 1rem !important;
}
.paddingTopMedium {
  padding-top: 3rem !important;
}
.paddingTopLarge {
  padding-top: 6rem !important;
}
.paddingTopXLarge {
  padding-top: 8rem !important;
}
.paddingBottomXSmall {
  padding-bottom: 0.5rem !important;
}
.paddingBottomSmall {
  padding-bottom: 1rem !important;
}
.paddingBottomMedium {
  padding-bottom: 3rem !important;
}
.paddingBottomLarge {
  padding-bottom: 6rem !important;
}

.noUnderline {
  text-decoration: none;
  color: black;
}
.inactive {
  pointer-events: none !important;
}
.constructionCalculatorInput label {
  color: black !important;
}
.textBlack {
  color: black !important;
}
.textBlack .MuiFormLabel-root {
  color: black !important;
}
.textWhite {
  color: white !important;
}
.minHeightTen {
  min-height: 26rem;
}

.hidden {
  display: none !important;
}
/* TopBar */

.topBarContainer {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  background-color: #f7f7f7;
}
.topLogo {
  max-height: 5rem;
  max-width: 100%;
}
/* Bottom Navigation */
.MuiBottomNavigationAction-root.Mui-selected {
  color: white !important;
}
.MuiBottomNavigationAction-root {
  color: rgba(255, 255, 255, 0.7) !important;
}

/* Card Content */
.MuiCardContent-root {
  background-color: gainsboro;
  background: black;
  position: relative;
  top: -6rem;
}

/* Home Content */

.customBodySmall {
  line-height: 1.1 !important;
  font-size: 0.7rem !important;
}

.homeUserData {
  margin-bottom: 2rem !important;
}
.homeAuthHr {
  color: black;
  border-bottom: 2px solid black;
  width: 80%;
  float: right;
  top: -2rem;
  position: relative;
}
.homeUserButtons button {
  padding: 1rem;
}
.homeUserButtons .MuiButton-label {
  font-size: 1rem;
  text-align: left;
  justify-content: flex-start;
}
.textIconHeader {
  max-width: 75px;
}
.missionSection {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: black;
}
.missionSection h5 {
  text-transform: capitalize;
}
.keyFeaturesSection {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: black;
}

.keyFeaturesSection p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.feature {
  cursor: default;
}
.featureActive {
  cursor: default;
  text-decoration: underline;
}
.featureAsset {
  max-height: 25rem;
}
.footerSection {
  background-color: rgba(229, 229, 229, 0.4);
}
.player-wrapper {
  width: auto;
  height: auto;
}
.player-wrapper video {
  max-width: 100vw;
}
.react-player {
  padding-top: 56.25%;
  position: relative;
}

.react-player > div {
  position: absolute;
}
/* Property Pages */
.MuiMenuItem-root {
  color: white !important;
}
.MuiInputLabel-outlined {
  color: black !important;
}
.MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.93) !important;
  border-width: 2px !important;
}
.MuiOutlinedInput-notchedOutline legend {
  width: 71px !important;
}
.main-wrapper {
  height: 29vh;
  width: 100%;
  filter: drop-shadow(-1px 5px 3px #ccc);
}
.greyBackground {
  background-color: lightgrey;
}
.info-wrapper {
  margin-top: 15px;
}
.map-details {
  text-align: center;
  font-size: 1.2em;
}
.map-details span {
  font-weight: bold;
}
.search-input {
  font-size: 1.2em;
  width: 80%;
}
.MuiTable-root {
  background-color: #f7f7f7;
}
.MuiTableRow-root {
  background-color: #f7f7f7;
}
.propertyShare .MuiMenu-paper {
  right: 10px;
  left: unset !important;
}

/* Local Planning */
.localPlanningUl {
  margin-left: 0;
  padding-left: 1rem;
}

.localPlanningUl li {
  font-size: 0.7rem;
}

/* Resources */
.linkIcon {
  position: relative;
  top: 0.2rem;
  margin-right: 1rem;
  width: 1rem !important;
  height: 1rem !important;
}
.linkAnchor {
  color: black;
  font-size: 0.8rem;
}

/* Client Page */

.clientListItem {
  background-color: white !important;
  padding: 0.7rem !important;
  color: black !important;
  /* margin-right: 5rem; */
  width: 95% !important;
  margin: 0 auto !important;
  margin-bottom: 1rem !important;

  box-shadow: rgb(0 0 0 / 20%) 3px 5px 8px 0px !important;
}
.secondaryClientText {
  color: black !important;
  font-size: 0.7rem !important;
  white-space: pre-line !important;
}
.clientAvatar {
  width: 55px !important;
  height: 55px !important;
  margin-right: 1rem !important;
}

/* Carousel */
.myPropertiesRight {
  width: 2em !important;
  height: 2em !important;
  color: white !important;
}
.myPropertiesLeft {
  width: 2em !important;
  height: 2em !important;
  color: white !important;
}

.myPropertyButtons button {
  padding: 1rem;
}
.myPropertyButtons .MuiButton-label {
  font-size: 1rem;
  text-align: center;
}

.clientChooserContainer {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  z-index: 999;
}
.clientChooser {
  position: relative;
  z-index: 9;
  width: 92%;
  background-color: white;
  padding: 1rem;
  top: 10%;
  margin: 0 auto;
}
.clientChooser .MuiIconButton-label {
  color: black !important;
  margin-right: 1rem !important;
}

.notificationContainer {
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  z-index: 999;
}
.notification {
  position: relative;
  z-index: 9;
  /* width: 92%;
  background-color: white;
  padding: 1rem; */
  top: 20%;
  /* margin: 0 auto; */
}
.notification .MuiGrid-container {
  position: relative;
  z-index: 9;
  /* width: 92%; */
  /* background-color: white; */
  padding: 1rem;
  top: 20%;
  margin: 0 auto;
}
.notification .MuiGrid-container .MuiGrid-item {
  background-color: white;
  padding: 2rem;
}
.notification .MuiIconButton-label {
  color: black !important;
  margin-right: 1rem !important;
}
